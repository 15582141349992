<template>
  <Layout tituloPagina="Hotspot | Perfiles de usuario">
    <div class="row mb-4">
      <div class="offset-md-2 col-md-7 col-9 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarPerfiles()
          "
          :datoini="dato"
          placeholder="Dato del perfil a buscar"
        ></filtrador>
      </div>
      <div class="col-md-3 col-3 text-right">
        <router-link class="btn btn-success" :to="{ name: 'nuevoPerfilHotspot' }">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo perfil de usuario
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Listado de perfiles de usuario
          <small class="font-size-10">
            Aquí puedes ver los perfiles registrados
          </small>
        </h4>
      </div>
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover align-middle table-nowrap" id="tabla-perfiles">
            <thead>
              <tr>
                <th style="width: 60px">ID</th>
                <th style="width: 250px">Nombre</th>
                <th>Velocidad de carga</th>
                <th>Velocidad de descarga</th>
                <th>Tiempo fichas</th>
                <th>Expiración</th>
                <th>Fecha de creación</th>
                <th>Usuarios compartidos</th>
                <th class="text-center" style=" width: 180px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="perfil in perfiles" :key="perfil.id">
                <td class="fw-semibold">{{ perfil.id }}</td>
                <td>{{ perfil.nombre }}</td>
                <td class="fw-semibold">{{ perfil.velocidad_carga == '0k' || perfil.velocidad_carga == '0M' ? 'Ilimitada' : perfil.velocidad_carga }}</td>
                <td class="fw-semibold">{{ perfil.velocidad_descarga == '0k' || perfil.velocidad_descarga == '0M' ? 'Ilimitada' : perfil.velocidad_descarga }}</td>
                <td>{{ convertidorSegundos_a_DHM(perfil.tiempo_fichas) }}</td>
                <td>{{ convertidorSegundos_a_DHM(perfil.expiracion) }}</td>
                <td>{{ formatoFecha(perfil.created_at) }}</td>
                <td>
                  <span class="badge bg-info bg-gradient font-size-13">
                    {{ perfil.usuarios_compartidos }} Usuario{{ perfil.usuarios_compartidos==1 ? '':'s' }}
                  </span>
                </td>
                <td class="text-center">
                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{
                      name:'edicionPerfilHotspot',
                      params:{
                        id:perfil.id
                      }
                    }"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>
                  <button class="btn btn-danger btn-sm" @click="eliminarPerfil(perfil)">
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarPerfiles()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import PerfilSrv from '@/services/hotspot/PerfilSrv.js'

import moment from 'moment'
import Layout from '@/views/layouts/main'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoPerfiles',
  components: { Layout, Filtrador, Paginador },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoPerfiles.por_pagina') || 10),
      tc: 0, // Total de portales cautivos
      totpag: 0, // Total de páginas
      perfiles:[]
    };
  },

  created: function() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp)  self.epp  = parseInt(self.$route.query.epp)

    self.refrescarPerfiles()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarPerfiles({ page: n })
    },

    eliminarPerfil: function(perfil) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "¿Eliminar el perfil?",
        html: "¿Está seguro de eliminar el perfil <br><b>"+perfil.nombre+"</b>?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          PerfilSrv.eliminar(perfil.id).then(response => {
            swal.fire("Eliminado!", "El perfil \""+perfil.nombre+"\" a sido eliminado.", "success");
            self.refrescarPerfiles()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo eliminar el perfil \""+perfil.nombre+"\""
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      
      return dias+' dia '+horas+' hrs '+minutos+' min'
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    
    refrescarPerfiles: function() {
      var self = this,
        query = {
          dato: self.dato,
          page: self.page,
          epp: self.epp
        }

      iu.spinner.mostrar('#tabla-perfiles')

      this.$router.replace({
        query: query
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato
      }

      PerfilSrv.perfilesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoPerfiles.por_pagina', self.epp)
        let page = response.data
        self.perfiles = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarPerfiles()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se cargaron los perfiles para hotspot'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-perfiles')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-perfiles')
  }
}
</script>

<style scoped>

</style>